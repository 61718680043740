
.ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.site-layout-background {
    background: #fff;
}

.site-body {
    height: 100%;
}

.App {
    height: 100%;
}

#root {
    height: 100%;
}


.App-logo {
    height: 24vmin;
    pointer-events: none;
    display: flex;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
